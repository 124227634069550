<template>
  <div class="container">
    <BaseHeader title="Automação de tags" :navigation="navigation">
    </BaseHeader>

    <div class="container-table">
      <div class="box-total" v-if="!loading">
        <div class="total-vendas info"></div>
        <div>
          <p class="total-vendas">
            Total de automações:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <b-skeleton
        v-if="loading"
        width="100%"
        height="83px"
        style="border-radius: 10px"
      ></b-skeleton>

      <div class="header-table">
        <div class="buttons">
          <BaseButton
            @click="$router.push({ name: 'Tags' })"
            variant="info"
            v-if="!isMobile"
          >
            Lista de Tags
          </BaseButton>
          <BaseButton
            variant="primary"
            v-b-toggle.sidebarAutomacao
            v-if="!isMobile"
            @click="info = null"
          >
            Adicionar automação
          </BaseButton>
        </div>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1" v-if="!isMobile">
        <b-col cols="2" class="d-none d-md-block"> Quando? </b-col>

        <b-col cols="2" class="d-none d-md-block"> Qual tag? </b-col>

        <b-col cols="2" class="d-none d-md-block" v-if="!isMobile">
          O que fazer?
        </b-col>

        <b-col cols="2" class="d-none d-md-block" v-if="!isMobile">
          Qual saída?
        </b-col>

        <b-col cols="2" class="d-none d-md-block"> Modificado em </b-col>

        <b-col cols="2" class="d-none d-md-block" style="padding-left: 50px"
          >Ações</b-col
        >
      </b-row>

      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && automacoes.length > 0 && !isMobile">
        <b-row class="Table-body" v-for="(item,index) in automacoes" :key="index">
          <!-- id -->
          <b-col cols="2" class="d-none d-md-block">
            <span v-if="item.in_action == 'assoc'">Entrar</span>
            <span v-else>Sair</span>
          </b-col>

          <!--título -->
          <b-col cols="2">
            <b-col
              class="d-none d-md-block w-100"
              style="padding: 0 !important"
            >
              <span class="w-100" v-if="!item.in_id && filterTag(item.in_id)"
                >Sem tag</span
              >

              <span
                :class="item.tag_automation.color + '-tag'"
                class="w-100 tag"
                v-else
                >{{ item.tag_automation.name }}</span
              >
            </b-col>
          </b-col>

          <b-col cols="2" v-if="!isMobile">
            <span v-if="item.out_action == 'Form::assoc'">Cadastrar</span>
            <span v-else-if="item.out_action == 'CRM::assoc'">Associar CRM</span>
            <span v-else-if="item.out_action == 'CRM::desassoc'">Desassociar</span>
            <span v-else-if="item.out_action == 'Produc::proposal'"
              >Associar Proposta</span
            >
            <span v-else-if="item.out_action == 'Salesforce::removeLead'"
              >Remover Salesforce
            </span>
            <span v-else-if="item.out_action == 'Form::desassoc'">Remover</span>
            <span v-else-if="item.out_action == 'Webhook::tagAutomation'"
              >Enviar WebHook</span
            >
            <span v-else-if="item.out_action == 'Salesforce::sendLead'"
              >Adicionar Salesforce</span
            >
            <span v-else-if="item.out_action == 'Tag::assoc'"
              >Associar tag</span
            >
          </b-col>

          <b-col cols="2" v-if="!isMobile">
            <span v-if="item.out_id != 0 && (item.out_action == 'Form::assoc' || item.out_action == 'Form::desassoc')">
              {{ item && item.title ? item.title : fetchForm(item, index) }}
            </span>

            <span v-else-if="item.out_id != 0 && item.out_action == 'CRM::assoc' || item.out_action == 'CRM::desassoc'">
              {{item && item.title ? item.title : fetchCRM(item, index) }}
            </span>

            <span :class="`${item && item.selectedTag ? item.selectedTag.color : ''}-tag tag`" v-else-if="item.out_id != 0 && item.out_action == 'Tag::assoc'">
              {{item && item.title ? item.title : fetchTag(item, index) }}
            </span>

            <span
              v-else-if="
                (item.out_id == 0 &&
                  Object.keys(JSON.parse(item.out_arguments)).length == 0) ||
                item.out_id == 0
              "
            >
              Sem saída
            </span>
            <span v-else>
              {{ JSON.parse(item.out_arguments) ?
                JSON.parse(item.out_arguments).name
                  ? JSON.parse(item.out_arguments).name
                  : JSON.parse(item.out_arguments).url
              : '' }}
            </span>
          </b-col>

          <b-col cols="2">
            {{ item.updated_at | datetimem }}
          </b-col>
          <b-col cols="2" class="d-flex">
            <BaseButton
              v-b-toggle.sidebarAutomacao
              @click="openSidebar(item)"
              style="padding-left: 0 !important"
              variant="text-info"
            >
              Editar
            </BaseButton>
            <BaseButton
              @click="deletarAutomacao(item.id)"
              style="padding-left: 0 !important"
              variant="text-danger"
            >
              Deletar
            </BaseButton>
          </b-col>
        </b-row>
      </template>

      <template v-if="!loading && automacoes.length > 0 && isMobile">
        <b-row class="Table-body" v-for="item in automacoes" :key="item.ID">
          <b-col cols="1" class="d-none d-md-block">
            <span v-if="!item.in_id">Sem tag</span>
            <span v-else>{{ filterTag(item.in_id).name }}</span>
          </b-col>

          <b-col cols="3">
            {{ item.post_modified | moment }}
          </b-col>
          <b-col cols="4" class="d-flex">
            <BaseButton style="padding-left: 0 !important" variant="text-info">
              Editar
            </BaseButton>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="automacoes.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :disabled="true"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="loading"
        v-for="item in 10"
        :key="item.id"
        style="display: flex; width: 100%; flex-direction: column-reverse"
        class=""
      >
        <b-skeleton
          width="100%"
          height="80px"
          style="border-radius: 10px; margin-top: 5px"
        ></b-skeleton>
      </div>

      <b-row
        v-if="!loading && !automacoes.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum automação foi encontrada</p>
      </b-row>
      <!-- END BODY -->
      <SidebarAutomacao :fetched_crms="fetched_crms" :info="info" @add-automation="fetchAutomacoes()" />
    </div>
  </div>
</template>
<script>
import TagAutomationService from "@/services/resources/TagAutomationService";
import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import SidebarAutomacao from "@/components/TagsAutomacao/SidebarAutomacao";

import moment from "moment-timezone";
import _ from "lodash";
import Vue from "vue";
const serviceListarAutomacoes = TagAutomationService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

export default {
  name: "ListagemVendas",
  components: {
    BaseButton,
    Paginate,
    BaseHeader,
    SidebarAutomacao,
  },
  data() {
    return {
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },

        { link: "Tags", to: "/dynamicRoute/apps/Tags" },
        { link: "Automação de Tags", to: this.$route.fullPath },
      ],
      automacoes: [],
      client: {
        width: 0,
      },
      total: 0,
      info: null,
      search: null,
      loading: true,
      leads: [],
      filters: {},
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      fetched_forms: [],
      fetched_crms: [],
      fetched_tags: [],
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    active() {
      return this.$route.name;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  methods: {
    fetchForm(item, index){
      if(item.stop){
        return;
      };
      
      Vue.set(this.automacoes[index], 'stop', true);
      
      serviceForm
        .read({id: item.out_id})
        .then((resp) => {
          Vue.set(this.automacoes[index], 'title', resp.title);
        });      
    },
    fetchTag(item, index){
      if(item.stop){
        return;
      };
      
      Vue.set(this.automacoes[index], 'stop', true);
      
      serviceTag
        .read({id: `show/${item.out_id}`})
        .then((resp) => {
          Vue.set(this.automacoes[index], 'title', resp.name);
          Vue.set(this.automacoes[index], 'selectedTag', resp);
        });      
    },
    fetchCRM(item, index) {
      if(item.stop){
        return;
      };
      
      Vue.set(this.automacoes[index], 'stop', true);

      const parsed_arguments = JSON.parse(item.out_arguments);
      servicePipeline.read({ id: parsed_arguments.pipeline_id })
      .then((resp) => {
        Vue.set(this.automacoes[index], 'title', resp.title);
      })
      .catch(() => {
      });
    },
    filterTag(id_tag) {
      var tags = this.$store.getters.tags;
      var result = tags.find((x) => x.id === id_tag);
      if (result === undefined) {
        return { tag: "error" };
      }
      return result;
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchAutomacoes(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchAutomacoes(this.search);
    },
    openSidebar(dados) {
      this.info = dados;
    },
    fetchAutomacoes(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;

      let data = {
        page: this.pagination.currentPage,
      };

      if (this.search) {
        data.search = this.search;
      }

      serviceListarAutomacoes
        .search(data)
        .then((response) => {
          this.automacoes = response.data;
          localStorage.setItem("teste", JSON.stringify(this.automacoes));
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    deletarAutomacao(id) {
      Vue.swal({
        title: "Automação de tags",
        text: `Deseja deletar a automação selecionada?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: `delete/${id}`,
          };
          serviceListarAutomacoes
            .destroy(data)
            .finally(() => {
              this.selected_lead = [];
              this.loading = false;
              this.fetchAutomacoes();
              this.$bvToast.toast("Automação deletada com sucesso", {
                title: "Automação de tags",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar automação", {
                title: "Automação de tags",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchAutomacoes(this.search);
    }, 500),
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchAutomacoes();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchAutomacoes();
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  gap: 15px;
}
@media (max-width: 768px) {
  .col-1 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-2 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-3 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.break {
  word-break: break-all;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: flex-end;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }

  .Table-body {
    gap: 50px;
  }
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}

.tag {
  padding: 6px 4px;
  border-radius: 5px;
  display: block;
  width: 100px !important;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

.info-tag {
  background: var(--blue2);
  color: var(--bluee);
}

.danger-tag {
  background: var(--red2);
  color: var(--red);
}

.warning-tag {
  background: var(--orange2);
  color: var(--orange);
}

.primary-tag {
  background: var(--greenn2);
  color: var(--greenn);
}

.success-tag {
  background: var(--blue2);
  color: var(--bluee);
}

.default-tag {
  background: rgba(170, 170, 170, 0.301);
}
</style>
